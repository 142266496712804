import request from '@/network/request.js'
/**
 * 获取儿子视频列表信息
 */
export function lists_mainvideo(data) {
	return request({
		url: '/admin/dramavideo/lists_sonvideo',
		method: 'post',
		data,
	})
}

/**
 * 增加|修改儿子视频列表信息
 */
export function add_up_mainvideo(data) {
	return request({
		url: '/admin/dramavideo/add_up_sonvideo',
		method: 'post',
		data,
	})
}

/**
 * 删除|批量儿子列表的视频信息
 */
export function dels_mainvideo(data) {
	return request({
		url: '/admin/dramavideo/dels_sonvideo',
		method: 'post',
		data,
	})
}



